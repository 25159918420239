import React from 'react';
import useIsWebView from '@app/shared/hooks/useIsWebView';
import { useWebViewEvents } from '@app/shared/hooks/useWebViewEvents';
import { WebViewEvents } from '@app/shared/config/webViewEvents';
import { getWindow } from '@app/shared/utils/window';
import Error from '@pedidosya/web-fenix/pages/Error';

export const Error404 = () => {
  const isWebView = useIsWebView();
  const { fire } = useWebViewEvents();

  /**
   * onClose
   *
   * @description Function to close de error page, if it is webview
   * fire a event otherwise if it is a web just call to history back
   */
  const onClose = () => {
    const wn: (Window & typeof globalThis) | null = getWindow();
    if (wn) {
      if (isWebView) {
        fire(WebViewEvents.CLOSE, {});
      }

      wn.history.back();
    }
  };

  return <Error errorCode={404} primaryActionLabel="Ir al inicio" primaryActionClick={onClose} />;
};
