import React, { FC } from 'react';
import type { RouterAppProps } from './RouterApp.type';
import { Routes, Route } from 'react-router-dom';
import { Routes as RouterConfig } from '@app/shared/config/routes';
import { Error404 } from '@app/shared/components/v2/Error404';
import loadable from '@loadable/component';
import BrandLoader from '@pedidosya/web-fenix/animations/PeyaLoader';

const LoadableOrders = loadable(() => import('@app/orders/Orders'), {
  resolveComponent: (components) => components.Orders,
  fallback: <BrandLoader position="center" />,
});

export const RouterApp: FC<RouterAppProps> = ({ children, location }) => {
  return (
    <Routes>
      <Route path={RouterConfig.RECREATION_ORDERS} element={<LoadableOrders />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};
