import React, { FC } from 'react';
import { ErrorPage } from '@app/shared/components/ErrorPage';
import { sentryHub } from '@app/shared/utils/sentry';
import type { ErrorBoundaryProps } from './ErrorBoundary.type';
import FenixProvider from '@pedidosya/web-fenix/contexts/FenixContext';

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({ error }) => {
  if (error?.__sentry_captured__) {
    // Allow the hub to re-catch the error if the main sentry instance already caught it
    delete error.__sentry_captured__;
  }

  sentryHub.captureException(error);

  return (
    <FenixProvider>
      <ErrorPage />
    </FenixProvider>
  );
};
